/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import BaseLayout from '../layouts/BaseLayout'
import SEO from '../components/seo'

import useWindowSize from '../util/UseWindowSize'


const About = () => {
  const size = useWindowSize()
  const my1 = css`
  background-image:url(/images/about.jpeg);
  height: ${size.height}px`

  const mySmall = css`font-size: 50%;`

  return (
    <BaseLayout>
      <SEO
        title="About"
      />
    <div id="colorlib-main">
      <section className="ftco-about img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
        <div className="container-fluid px-0">
          <div className="row d-flex">
            <div className="col-md-6 d-flex">
              <div className="img d-flex align-self-stretch align-items-center" css={my1}>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="text px-4 pt-5 pt-md-0 px-md-4 pr-md-5 ftco-animate fadeInUp ftco-animated">
                <h2 className="mb-4">I'm <span>OT <small css={mySmall}>(poipoii)</small></span> a Software Developer</h2>
                <p><i className="icon-pencil"></i>...</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </BaseLayout>
  )
}

export default About
